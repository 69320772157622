.container {
    padding: 0px;
    height: 100%;
}

.container > .col-md-6:first-child,
.container > .col-md-6:nth-child(2n + 1) {
    padding-left: 0px;
    padding-right: 15px;
}

.container > .col-md-6:nth-child(2n + 2) {
    padding-right: 0px;
    padding-left: 15px;
}

.container > .col-md-6 {
    padding-bottom: 30px;
}

.container-fluid {
    padding: 0px 15px 0px 15px;
    margin: -40px -15px 20px -15px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.container-fluid > .gradient {
    margin: -20px -15px 0px -15px;
    padding: 71px 15px 0px 15px;
    height: 600px;
}

.container-fluid > .gradient > .image {
    padding-bottom: 30px;
    text-align: center;
    display: block;
    margin: 0 auto;
    max-height: 65%;
}

.container-fluid > .gradient > h1 {
    font-family: Roboto;
    font-size: 64px;
    font-weight: 500;
    letter-spacing: 1.1px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
}

.container-fluid > .gradient > p {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
}

.container > .col-md-8 > .col-md-12 > .col-md-4 > h3 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #000000;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    text-align: left;
}

.container > .col-md-8 > h1 {
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #000000;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 80px;
    text-align: center;
}

.container > .col-md-8 > .col-md-12 > .col-md-4 > p {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0.4px;
    color: #000000;
    margin-bottom: 20px;
}

#banner > .gradient > h1 {
    font-family: Roboto;
    font-size: 64px;
    font-weight: 500;
    letter-spacing: 1.1px;
    color: #ffffff;
    text-align: center;
    margin-top: 100px;
}

@media (max-width: 992px)
{
    .container-fluid > .gradient {
        padding: 32px 15px 0px 15px;
        height: 400px;
    }
}

@media (max-width: 576px)
{
    .container-fluid > .gradient {
        height: 340px;
    }

    .container-fluid > .gradient > .image {
        padding: 0;
    }

    .container-fluid > .gradient > h1 {
        font-size: 40px;
    }
}

@media (max-width: 375px)
{
    .container-fluid > .gradient {
        height: 300px;
    }

    .container-fluid > .gradient > .image {
        padding: 0;
    }

    .container-fluid > .gradient > h1 {
        font-size: 32px;
    }
}