.navbar {
    height: 80px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: none;
}

.navbar-nav > li > a {
    line-height: 80px;
    padding: 0px 30px 0px 30px;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.navbar h1 {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    line-height: 80px;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #000000;
}

.navbar h3 {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #000000;
    display: inline-block;
}

@media (max-width: 331px) {
    .navbar h3,
    .navbar h1 {
        font-size: 14px;
    }
}

@media (min-width: 332px) and (max-width: 400px) {
    .navbar h3,
    .navbar h1 {
        font-size: 16px;
    }
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:hover {
    color: #29aae2;
}

.navbar-default .navbar-brand {
    color: #ffffff;
}

.navbar-nav {
    margin: 0 -15px;
}

.navbar-default .icon-largeArrowLeft {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 20px;
}