.panel {
    cursor: pointer;
}

.panel-default {
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: none;
    height: 350px;
    background-size: cover;
    background-position: center center;
}

@media (max-width: 991px) {
    .organisation-block {
        padding: 0 0 30px 0 !important;
    }
}

.panel-body {
    padding: 0px;
}

.panel-body {
    background-position: center center;
    background-size: cover;
}

.panel > .black-layer {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding-top: 75px;
    height: 100%;
    width: 100%;
}

.panel > .black-layer > .panel-body > .image {
    max-height: 200px;
    text-align: center;
    display: block;
    margin: 0 auto;
    padding: 0 10px;
}

.panel-default > .black-layer > .panel-footer {
    background-color: transparent;
    padding: 0px;
    border: 0px;
}

.panel-default > .black-layer > .panel-footer > h2 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
}

.panel-white {
    background-position: center center;
    background-size: cover;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    border: none;
    padding: 0px 10px 0px 0px;
}

.panel-white:last-child {
    padding: 0px;
}

.panel-white > .panel-body {
    padding-top: 53px;
    padding-bottom: 53px;
}

.panel-white > .panel-body > .image {
    height: 80px;
    text-align: center;
    display: block;
    margin: 0 auto;
}

.panel-white > .panel-footer {
    height: 70px;
    border: none;
    background-color: #ffffff;
}

.panel-white > .panel-footer > .col-md-12 {
    opacity: 0.6;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
    color: #000000;
}

.panel-white > .panel-footer > p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: #000000;
    word-wrap: break-word;
}

.panel-active {
    box-shadow: 0 0 20px 0 rgba(40, 169, 226, 0.4);
}

.panel-active > .panel-footer > .col-md-10 {
    margin-top: -5px;
    z-index: -1;
    height: 30px;
    box-shadow: 0px 8px 30px 0px rgba(40, 169, 226, 0.1), 0px 8px 30px 0px rgba(0, 0, 0, 0.1);
}