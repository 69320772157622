html {
    height: 100%;
}

body {
    background-color: #f7fafc;
    font-family: Roboto;
    font-weight: 400;
    font-size: 13px;
    padding: 120px 15px 40px 15px;
}

a:hover {
    text-decoration: none;
}

.pointer {
    cursor: pointer;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
    background-color: rgba(245, 166, 35, 0.1);
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xl-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xl-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xl-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xl-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xl-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xl-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xl-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xl-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xl-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xl-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xl-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12,
.col-xl-12 {
    padding-right: 10px;
    padding-left: 10px;
}

.col-no-padding {
    padding-right: 0px;
    padding-left: 0px;
}

.brand {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-size: 1.4em;
    line-height: 80px;
    padding: 0px 68px 0px 68px;
}

.brand:hover {
    color: #ffffff;
}

.body-login {
    background-color: #0c3e69;
    padding-top: 15%;
}

ul.alert {
    list-style: none;
}

h2 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 30px;
}

.clear {
    clear: both;
}

.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xl-3 {
    padding: 0px 10px 0px 0px;
}

.container > .col-md-8 > p {
    text-align: center;
}