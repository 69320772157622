.form-control {
    height: 50px;
    box-shadow: none;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 6px 20px;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0.3px;
    font-family: Roboto;
    color: #000000;
}

.form-horizontal > .form-group > div > .bootstrap-select > .btn-default, .panel-body > .bootstrap-select > .btn-default, .form-horizontal .col-md-6 .form-group .btn-default, .form-horizontal .col-md-12 .form-group .btn-default {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0.3px;
    font-family: Roboto;
    color: #000000;
}

.form-control::-webkit-input-placeholder {
    opacity: 0.4;
}

.form-control:-moz-placeholder {
    opacity: 0.4;
}

.form-control::-moz-placeholder {
    opacity: 0.4;
}

.form-control:-ms-input-placeholder {
    opacity: 0.4;
}

.form-horizontal .form-group {
    margin-bottom: 10px;
}

.form-horizontal .form-group:last-child {
    margin-bottom: 0px;
}

.form-horizontal > .form-group > div > .bootstrap-select > .btn-default,
.panel-body > .bootstrap-select > .btn-default,
.form-horizontal .col-md-6 .form-group .btn-default,
.form-horizontal .col-md-12 .form-group .btn-default {
    height: 50px;
    border-color: rgba(0, 0, 0, 0.1);
}

.form-horizontal .col-md-6 {
    padding: 0px 0px 0px 0px;
}

.form-horizontal .col-md-6 label,
.form-horizontal .col-md-12 label {
    opacity: 0.6;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #000000;
    padding: 0px 0px 8px 0px;
    margin: 0px;
}

.form-horizontal > .col-md-6 {
    margin-top: 20px;
    margin-bottom: 0px;
}

.form-horizontal.panel-body {
    padding-bottom: 40px;
}

.form-horizontal > .panel-block > .col-md-6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.form-horizontal > .panel-block {
    padding-left: 10px;
    padding-right: 10px;
}

.form-horizontal > .panel-block > .col-md-12 {
    padding: 0px 0px 0px 0px;
}

.form-horizontal.panel-body > .col-md-12 {
    padding: 20px 20px 0px 20px;
}

.form-horizontal.panel-body > .col-md-12 > .form-group {
    margin-right: 0px;
    margin-left: 0px;
}

.form-horizontal > .panel-block > .col-md-12 > .form-group {
    padding: 0px 20px 0px 20px;
    margin: 10px 0px 10px 0px;
}

.form-horizontal .col-md-6.odd .form-group {
    margin: 0px 20px 20px 20px;
}

.form-horizontal .col-md-6.even .form-group {
    margin: 0px 20px 20px 20px;
}

.form-horizontal .col-md-6 .form-group:last-child {
    margin-bottom: 0px;
}

@media (max-width: 991px) {
    .form-horizontal .col-md-3 {
        padding: 0;
    }
}

.col-md-3:last-child {
    padding: 0px;
}

.input-group > .input-group-addon {
    background-color: #ffffff;
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.1);
    padding-right: 0px;
}

.input-group > .form-control {
    border-left: none;
}

.form-horizontal > .form-group.has-error > div > .bootstrap-select > .btn-default,
.form-horizontal .col-md-6 .form-group.has-error .btn-default,
.form-horizontal .col-md-12 .form-group.has-error .btn-default {
    border-color: #b94a48;
}

.input-group.has-error > .input-group-addon {
    border-color: #b94a48;
    background-color: #ffffff;
    color: #000000;
}

.input-group.has-error > .form-control {
    box-shadow: none;
}

.input-group {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.input-group > .form-control.first-in-group {
    border-left: solid 1px rgba(0, 0, 0, 0.1);
    border-right: none;
}

.input-group > .input-group-addon.middle-in-group {
    padding-right: 12px;
    border-left: none;
    border-right: none;
}

.input-group .form-control:not(:first-child):not(:last-child) {
    -webkit-border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    margin-top: 0px;
    line-height: 59px;
}

.form-horizontal .hr-line-dashed {
    float: left;
    width: 100%;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    margin: 0px -20px 20px -20px;
    padding: 40px 20px 0px 20px;
}

.title > h3 {
    margin: 80px 0px 0px 0px;
    padding: 0px 20px 0px 20px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #313232;
    padding-bottom: 10px;
}

@media (min-width: 992px) {
    .title-spacing {
        margin-bottom: 80px !important;
    }
}

.ms-container {
    width: 100%;
    padding: 0px 20px 0px 20px;
}

.ms-container .ms-selectable, .ms-container .ms-selection {
    width: 48%;
}

.ms-container .ms-list {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(0, 0, 0, 0.05);
    min-height: 200px;
    height: auto;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
    height: 50px;
    line-height: 50px;
    padding: 0px 20px 0px 20px;
    color: #000000;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
    background-color: #f4fbfd;
    color: #000000;
}

.custom-header {
    height: 50px;
    line-height: 50px;
    font-size: 1.2em;
    color: #000000;
}

.form-horizontal .datepicker {
    padding: 6px 12px 6px 12px;
}

.datepicker-dropdown {
    z-index: 10000;
}

.bootstrap-select > .dropdown-toggle {
    padding: 6px 20px;
}

textarea.form-control {
    height: 150px;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"] + label:before {
    cursor: pointer;
    border: 3px solid rgba(0, 0, 0, 0.6);
    border-radius: 2.5px;
    content: "\00a0";
    display: inline-block;
    font-size: 25px;
    height: 25px;
    margin: 0 15px 0 0;
    padding: 0;
    vertical-align: bottom;
    width: 25px;
}

input[type="checkbox"]:checked + label:before {
    content: "\2713";
    line-height: 20px;
    text-align: center;
}

input[type="checkbox"]:checked + label:after {
    font-weight: bold;
}

label > a {
    color: #337ab7;
    font-style: italic;
}

.form-horizontal > .btn-col {
    text-align: center;
}

.form-horizontal.panel-body select {
    -webkit-appearance: none;  /*Removes default chrome and safari style*/
    -moz-appearance: none; /* Removes Default Firefox style*/
    background: #ffffff;  /*Adds background-image*/
    text-indent: 0.01px; /* Removes default arrow from firefox*/
    text-overflow: "";  /*Removes default arrow from firefox*/
    padding-right: 40px;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 6px), calc(100% - 15px) calc(1em + 6px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
}

.btn-primary {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    background-color: #29aae2;
    border: none;
    padding: 20px 115px 20px 115px;
    margin: 0 auto;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.20);
}

.btn-primary:hover {
    background-color: #1a75bc;
}

.bootstrap-select.btn-group .dropdown-menu li a {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0.3px;
    font-family: Roboto;
    color: #000000;
}

.form-horizontal.panel-body > .checkbox {
    padding-top: 30px;
}

.form-horizontal.panel-body > .accept {
    padding-top: 50px;
}

.dropdown-menu {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: none;
}

.dropdown-menu > li > a {
    height: 59px;
    line-height: 59px;
}

.dropdown-menu > li {
    border-bottom: 1px solid #e5e5e5;
}

.dropdown-menu > li:last-child {
    border: none;
}

.has-error .form-control {
    box-shadow: 0px 1px 8px 0px rgba(208, 1, 26, 0.30);
}

@media (max-width: 992px) {
    .form-horizontal.panel-body .title h3 {
        margin: 40px 0px 0px 0px;
    }
}

@media (max-width: 576px) {
    .form-horizontal.panel-body .title h3 {
        padding: 0;
        margin: 32px 0px 0px 0px;
    }

    .form-horizontal.panel-body .btn-primary {
        padding: 20px 70px;
    }

    .form-horizontal.panel-body .col-md-12 {
        padding: 20px 0px 0px 0px;
    }

    .form-horizontal.panel-body .col-md-6 .form-group {
        padding: 0;
        margin: 0;
    }
}