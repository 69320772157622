#flash-messages {
    position: fixed;
    top: 100px;
    right: 20px;
    z-index: 9999;
    animation: flash-message 8s forwards;

    div {
        padding: 30px 50px;
        background-color: #1a75bc;
        color: white;
        border-radius: 4px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 22px 26px -10px rgba(0, 0, 0, 0.15);
        margin-bottom: 25px;
    }
}

@keyframes flash-message {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }
    20% {
        opacity: 1;
        transform: translateX(0px);
    }
    80% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        display: none;
        transform: translateX(200px);
    }
}